@import "../../global.scss";

.Faqs {
  .accordions {
    @include tablet {
      display: flex;
      flex-direction: column;
      gap: $space-m;
    }
  }
  h1 {
    color: $color-primary;
    margin-bottom: 50px;
  }
  .accordion {
    display: flex;
    flex-direction: column;
    gap: $space-m;

    &-item {
      border-radius: $primary-border-radius !important;
      overflow: hidden;
    }

    &-button {
      border-radius: 0;
      border: none;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      &:not(.collapsed) {
        background-color: $color-secondary;
        color: $color-white;
        box-shadow: none;
      }
    }

    &-body {
      color: $color-text;
    }
  }
}
