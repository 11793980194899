@import "../../global.scss";

.DashboardSidebar {
  &__title {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      align-items: center;
      gap: $space-s;

      h4 {
        margin-bottom: 0;
        color: $color-primary;
      }
    }
  }

  &__profileSummary {
    ul {
      padding-left: 0 !important;
      display: flex;
      flex-direction: column;
      gap: $space-s;
      li p {
        font-size: 12px !important;
        margin-bottom: 0;
      }
    }

    &-item {
      display: flex;
      gap: $space-s;

      span {
        font-size: 12px;
      }
    }

    &-image img {
      width: 14px;
      height: 14px;
    }
  }

  &__logout {
    display: inline-flex;
    width: 100% !important;
  }
}
