@import "../../global.scss";

.hero {
  height: 800px;
  display: flex;
  align-items: center;
  background-image: url("../../../public//assets/Images/hero-background.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center right;
  justify-content: center;
  @media (max-width: 2000px) {
    height: 400px;
  }
  @include desktop {
    height: 350px;
  }

  @include laptop {
    background-size: 35%;
  }
  @include tablet {
    background-size: 0%;
    height: 100%;
    padding: 0 0 $space-xl 0;
  }

  h1 {
    color: $color-primary;
  }
  p {
    color: $color-black !important;
  }
  &__text {
    padding: $space-xl 0;
    @include mobile {
      padding: $space-l;
    }
    .buttons {
      @include tablet {
        flex-direction: column;
      }
    }
  }
}
