@import "../../global.scss";

.Footer {
  background-color: $color-secondary-background;
  ul {
    padding-left: 0;
    @include mobile {
      order: 1;
    }
  }
  &__copyright {
    li,
    p {
      font-size: 12px !important;
    }
    @include mobile {
      display: flex;
      flex-direction: column;
      gap: $space-m;
    }
    .nav-link {
      text-decoration: underline;
    }

    p {
      @include mobile {
        order: 2;
      }
    }
  }

  .nav-link {
    &:hover {
      color: $color-primary;
    }
  }

  &__links {
    @include mobile {
      flex-direction: column;
    }
  }
}
