@import "../../global.scss";

.Login {
  form {
    width: 50%;
    background-color: $color-secondary-background;
    padding: $space-l;
    border-radius: $primary-border-radius;
    @include laptop {
      width: 75%;
    }
    @include tablet {
      width: 100%;
    }
  }
  p {
    display: flex;
    gap: $space-s;
  }
  a {
    color: $color-secondary;
    text-decoration: underline;

    &:hover {
      color: $color-primary;
    }
  }
}
