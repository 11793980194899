@import "../../global.scss";

.ContactPage {
  .IconBoxList .row {
    margin: 0 !important;
  }

  .IconBoxList__background {
    h2 {
      padding-bottom: 0 !important;
    }
    @include tablet {
      height: 100%;
      padding-top: 0;
    }
    .row {
      &:first-child {
        padding: $space-l 0;
      }
    }
  }
}
