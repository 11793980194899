@import "../../global.scss";
.SingupOnboarding {
  .rounded-circle.active,
  .progress-bar {
    background: $color-primary-gradient;
    color: $color-white;
  }

  .rounded-circle.default {
    background-color: $color-secondary-background;
    color: $color-text;
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    gap: $space-xl;
  }

  .form-check-input:checked {
    background-color: $color-primary;
  }
}
