@import "../../global.scss";

.SignUp {
  .card {
    &-title {
      font-size: 24px;
      color: $color-secondary;
    }
    &-body {
      display: flex;
      flex-direction: column;
      gap: $space-m;

      ul {
        padding-left: 0;

        li {
          display: flex;
          align-items: center;
          gap: $space-s;
          margin-bottom: $space-s;
          font-size: 14px;
        }
        img {
          width: 16px;
        }
      }
    }
    img {
      width: 48px;
    }
  }
}
