@import "../../global.scss";

.custom-button {
  padding: $space-m $space-l;
  border-radius: $secondary-border-radius;
  border: none;
  transition: all 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-m;
  @include mobile {
    font-size: 14px;
  }
  &.primary {
    background: $color-primary-gradient;
    color: $color-white !important;

    &:hover {
      background: $color-secondary-gradient;
    }
  }

  &.primaryOutline {
    background-color: transparent !important;
    border: solid 2px $color-primary !important;
    color: $color-primary;
  }
  &.whiteOutline {
    background-color: transparent !important;
    border: solid 2px $color-white !important;
    color: $color-white;
  }
  &.white {
    color: $color-primary;
    background-color: $color-white;
  }

  &.disabled {
    background-color: transparent !important;
    border: solid 2px $color-border !important;
    color: $color-text;

    &:hover {
      box-shadow: none;
      transform: translateY(0);
    }
  }

  &:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
}
