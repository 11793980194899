/*===========  Fonts ==================*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300..800&display=swap");
/*===========  Spacing ==================*/

$space-s: 0.5rem;
$space-m: 1rem;
$space-l: 2.4rem;
$space-xl: 3.2rem;
$space-xxl: 4.8rem;
$space-xxxl: 11.2rem;

/*===========  Responsive ==================*/

$width-lg: 1200px;
$width-md: 992px;
$width-sm: 768px;
$width-xs: 567px;

@mixin desktop {
  @media (max-width: #{$width-lg}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$width-md}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$width-sm}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$width-xs}) {
    @content;
  }
}

/*===========  Colors ==================*/

$color-primary: #8b268c;
$color-secondary: #465ea6;
$color-tertiary: #18c5d9;
$color-black: #0d0d0d;
$color-white: #fff;
$color-text: #767676;

$color-primary-background: #f5f5f5;
$color-secondary-background: #fff;
$color-border: #e6e6e6;

$color-primary-gradient: linear-gradient(179.491deg, #8b268c 0%, #465ea6 100%);
$color-secondary-gradient: linear-gradient(
  -104.393deg,
  #8b268c 0%,
  #465ea6 100%
);

/*===========Border Radius ==================*/

$primary-border-radius: 10px;
$secondary-border-radius: 100px;

/*===========  Default element styles ==================*/
html {
  scroll-behavior: smooth;
}

body {
  font-weight: 400;
  background-color: $color-primary-background !important;
}

* {
  font-size: 16px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Poppins", sans-serif !important;
}

h1 {
  font-size: 2.5rem !important;
}

h2 {
  color: $color-primary !important;
  font-size: 2rem !important;
  span {
    font-size: inherit !important;
    font-family: "Poppins", sans-serif !important;
    background: $color-primary-gradient;
    -webkit-background-clip: text; // Clip the background to the text
    -webkit-text-fill-color: transparent;
  }
}

section {
  padding: 112px 0;

  @include tablet() {
    padding: 80px 0;
  }
}
.container {
  height: 100%;

  @include tablet {
    width: 100%;
  }
}

input,
select {
  border-radius: $secondary-border-radius !important;
  border: 1px solid $color-border !important;
}

textarea {
  border-radius: $primary-border-radius !important;
  border: 1px solid $color-border !important;
}
.heading-color-secodary {
  color: $color-secondary;
}
p {
  color: $color-text;
}

a {
  text-decoration: none !important;
}

.card {
  border: none !important;
  background-color: transparent !important;
  height: 100% !important;
}

.card-header {
  border: none !important;
  background-color: $color-primary-background !important;
  padding: 0;
  h4 {
    color: $color-secondary !important;
  }
}

.card-body {
  padding: $space-l !important;
  border-radius: $primary-border-radius !important;
  border: none;
  overflow: hidden !important;
  background-color: $color-secondary-background;
}

.form-check-input:checked {
  background-color: $color-primary !important;
}
