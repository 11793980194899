@import "../../global.scss";

.Dashboard {
  display: flex;
  flex-direction: column;

  &__navigation {
    display: flex;
    align-items: center;
    gap: $space-m;
    @include tablet {
      position: fixed;
      top: 55px;
      background-color: $color-secondary-background;
      width: 100%;
      left: 0;
    }
    &-item {
      color: $color-text;

      &.active {
        color: $color-primary;
        border-bottom: solid 2px $color-secondary;
      }
    }
  }
}
