@import "../../global.scss";

.Navigation {
  background-color: $color-secondary-background;
  position: relative;
  overflow: hidden;
  padding: 0 !important;
  .container {
    z-index: 2;
  }
  & .navbar-brand {
    flex: 1;
  }
  #basic-navbar-nav {
    @include laptop {
      height: 250px;
    }
  }
  .navbar-nav-scroll {
    width: 100%;

    justify-content: center;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: $space-l;

    & .nav-link {
      color: $color-white;
      &.active {
        color: $color-white !important;
      }
      &:hover {
        color: $color-tertiary;
      }
    }
  }

  & .active {
    color: $color-secondary !important;
    font-weight: 600;
  }

  &__background {
    position: absolute;
    right: 0;
    width: 40%;
    height: 100%;
    top: -20px;

    @include laptop {
      top: -20px;
    }
  }

  .navbar-toggler {
    border-color: $color-white !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: $space-s;

    &:focus {
      box-shadow: 0 0 0 0.1rem $color-tertiary;
    }
    span {
      height: 2px;
      background-color: $color-white;
      width: 25px;
      transform-origin: left;
      transition: all 0.2s ease;
      border-radius: 50px;
      &:first-child {
        transform: rotate(45deg) translateY(-3px) translateX(1px);
      }

      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        transform: rotate(-45deg) translateY(3px) translateX(1px);
      }
    }

    &.collapsed {
      span {
        transform: rotate(0) translateX(0) translateY(0);
        opacity: 1;
        border-radius: 50px;
      }
    }
    &-icon {
      background: none;
    }
  }
}
