@import "../../global.scss";

.CallToAction {
  background: $color-secondary-gradient;

  h2,
  p {
    color: $color-white !important;
  }

  .buttons {
    @include mobile {
      flex-direction: column;
    }
  }
}
