@import "../../global.scss";

.IconBoxList {
  background-color: $color-secondary-background;

  span {
    font-family: "Poppins", sans-serif !important;
    background: $color-primary-gradient;
    -webkit-background-clip: text; // Clip the background to the text
    -webkit-text-fill-color: transparent;
  }

  &__background {
    background-repeat: no-repeat !important;
    border-radius: $primary-border-radius;
    background-attachment: fixed !important;

    @include tablet {
      background-attachment: scroll !important;
      background-size: cover;
      padding: $space-xl 0;
      background-position: center center !important;
    }
    .row {
      margin: $space-xl;
      border-radius: $primary-border-radius;
      padding: $space-l;
      @include laptop {
        margin: $space-m;
        padding: $space-m;
      }
      @include tablet {
        margin: $space-s;
        padding: $space-s;
      }
    }
  }
}
