@import "../../global.scss";

.glide {
  img {
    height: auto;
    width: 100%;
  }

  &__arrow {
    &--left {
      left: -10px;
      @include tablet {
        left: -20px;
      }

      @include mobile {
        left: -15px;
      }
    }
    &--right {
      right: -10px;

      @include tablet {
        right: -20px;
      }

      @include mobile {
        right: -15px;
      }
    }
    &--left,
    &--right {
      border: none !important;
      box-shadow: none;
    }

    svg {
      width: 32px;
      height: 32px;
      @include tablet {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__slide {
    p {
      background-color: $color-secondary;
      color: $color-white;
      padding: $space-s $space-m;
      font-size: 12px;
      border-radius: 0 0 $primary-border-radius $primary-border-radius;
      margin-top: -1px;
    }
  }
}
