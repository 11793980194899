@import "../../global.scss";

.HowItWorks {
  background-color: $color-secondary-background;
  padding-top: 0;

  &__item {
    display: flex;
    gap: $space-m;
    &-number {
      display: inline-flex;
      justify-content: center;
      border-radius: $secondary-border-radius;
    }

    h5 {
      margin-bottom: 0;
      color: $color-secondary;
    }
  }
}
